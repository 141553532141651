import { InputBase, Stack, Button, SxProps, Theme, Typography, Fab, Tooltip } from "@mui/material"
import { FC, Dispatch, SetStateAction, useContext } from "react"
import { MeetingSearchFormContext } from "."
import AddIcon from "@mui/icons-material/Add"
import { TermChipBase } from "../TermChipBase"

export const SearchFormInput: FC<{
  input: string
  setInput: Dispatch<SetStateAction<string>>
  addTerm: () => void
  setInputFocused?: Dispatch<SetStateAction<boolean>>
  placeholder?: string
  toolTipTitle?: string
}> = ({ input, setInput, addTerm, setInputFocused, placeholder, toolTipTitle }) => {
  const inputElement = (
    <InputBase
      value={input}
      sx={{ flex: 1, minWidth: "22ch" }}
      inputProps={{
        "aria-label": `${placeholder}`,
        sx: {
          "&::placeholder": {
            opacity: "0.7",
          },
        },
      }}
      placeholder={placeholder}
      onChange={(event) => setInput(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === "Enter" && input.trim()) {
          addTerm()
        }
      }}
      onFocus={setInputFocused && (() => setInputFocused(true))}
      onBlur={setInputFocused && (() => setTimeout(() => setInputFocused(false), 150))}
    />
  )
  return toolTipTitle ? <Tooltip title={toolTipTitle}>{inputElement}</Tooltip> : inputElement
}

const TermChip: FC<{
  term: string
  createDelete: (item: string) => () => void
}> = ({ term, createDelete }) => {
  const {
    filterParams,
    searchFormData: { searchDateRange },
  } = useContext(MeetingSearchFormContext)
  return (
    <TermChipBase
      term={term}
      onDelete={createDelete(term)}
      filterParams={filterParams}
      searchDateRange={searchDateRange}
    />
  )
}

export const SearchFormTerms: FC<{
  terms: Set<string>
  setTerms: Dispatch<SetStateAction<Set<string>>>
  sx?: SxProps<Theme>
}> = ({ terms, setTerms, sx }) => {
  if (terms.size !== 0) {
    return (
      <Stack className="advanced-search-terms" direction="row" flexWrap="wrap" gap={0.5} paddingY="4px" sx={sx}>
        {Array.from(terms).map((term) => (
          <TermChip
            key={term}
            term={term}
            createDelete={(termInput) => () =>
              setTerms((prevTerms) => {
                prevTerms.delete(termInput)
                return new Set(prevTerms)
              })
            }
          />
        ))}
      </Stack>
    )
  }
  return null
}

export const SearchFormButton: FC<{
  addTerm: () => void
}> = ({ addTerm }) => (
  <>
    <Button
      variant="contained"
      color="secondary"
      sx={{
        color: "white",
        flexShrink: "0",
        display: { xs: "none", sm: "flex" },
      }}
      disableElevation
      onClick={addTerm}
    >
      <Typography>Add Term</Typography>
    </Button>
    <Fab
      size="small"
      color="secondary"
      sx={{
        boxShadow: 0,
        color: "white",
        width: 36,
        height: 36,
        display: { xs: "flex", sm: "none" },
      }}
      onClick={addTerm}
    >
      <AddIcon />
    </Fab>
  </>
)
