import { Button, TableCell, TextField, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useUpdatePerson } from "@api/admin/persons"
import { useUpdateVoicePersonId } from "@api/admin/voices"
import { Person } from "@src/interfaces/person"
import { AdminContact } from "@src/interfaces/contact"
import { VoiceWithSpeaker } from "@interfaces/voice"
import { useToast } from "@components/shared/ToastHook"
import { decidePersonFields } from "@functions/person"

export const PersonCells: FC<{
  // if voice_id is not null, update the voice with the person_id
  voice: VoiceWithSpeaker
  handleUpdateVoice: (voiceId: number, newVoice: VoiceWithSpeaker) => void
  person?: Person<AdminContact>
  contactData?: AdminContact
}> = ({ voice, handleUpdateVoice, person, contactData }) => {
  const [personData, setPersonData] = useState<Person<AdminContact> | undefined>(person)

  const decidedFields = decidePersonFields(personData, true)
  const [name, setName] = useState(decidedFields.name)
  const [title, setTitle] = useState(decidedFields.title)
  const [organization, setOrganization] = useState(decidedFields.organization)

  const setToast = useToast()

  const updateVoicePersonIdSuccess = (data: VoiceWithSpeaker) => {
    if (data) {
      handleUpdateVoice(data.id, { ...data, speaker: voice.speaker })
      setPersonData(data.person)
    }
    setToast("Voice updated successfully", 2000, undefined, "info")
  }

  const updatePersonSuccess = (data: Person<AdminContact>) => {
    if (data) {
      setPersonData(data)
    }
    setToast("Person updated successfully", 2000, undefined, "info")
  }

  const {
    mutate: updateVoicePersonId,
    isLoading: voiceIsLoading,
    data: updateVoiceResponse,
  } = useUpdateVoicePersonId(updateVoicePersonIdSuccess)
  const {
    mutate: updatePerson,
    isLoading: personIsLoading,
    data: updatePersonResponse,
  } = useUpdatePerson(updatePersonSuccess)

  useEffect(() => {
    if (updatePersonResponse) {
      setPersonData(updatePersonResponse?.data)
    }
  }, [JSON.stringify(updatePersonResponse?.data)])

  useEffect(() => {
    if (updateVoiceResponse) {
      setPersonData(updateVoiceResponse?.data.person)
    }
  }, [JSON.stringify(updateVoiceResponse?.data)])

  useEffect(() => {
    setPersonData(person)
  }, [JSON.stringify(person)])

  const [canUpdate, setCanUpdate] = useState(false)

  useEffect(() => {
    let canUpdateTemp = false
    // Allow update to make the person null
    if (personData?.id != voice.person_id) {
      canUpdateTemp = true
    }
    // If the person value has not changed, and there is no person associated, we do not want to allow update
    else if (!personData) {
      canUpdateTemp = false
    }
    // If the person value has not changed, but the person has a contact associated, we do not want to allow update
    else if (personData.contact) {
      canUpdateTemp = false
    }
    // If the person value has not changed, it does not have a contact associated, and the person values have changed, we want to allow update
    else if (personData.name !== name || personData.title !== title || personData.organization !== organization) {
      canUpdateTemp = true
    }
    setCanUpdate(canUpdateTemp)
  }, [name, title, organization, JSON.stringify(personData), voice.person_id])

  useEffect(() => {
    const { name, title, organization } = decidePersonFields(personData, true)
    setName(name)
    setTitle(title)
    setOrganization(organization)
  }, [JSON.stringify(personData)])

  return (
    <>
      <TableCell>
        {contactData ? (
          <Typography>{name}</Typography>
        ) : (
          <TextField value={name} onChange={(event) => setName(event.target.value)} size="small" />
        )}
      </TableCell>
      <TableCell>
        {contactData ? (
          <Typography>{title}</Typography>
        ) : (
          <TextField value={title} onChange={(event) => setTitle(event.target.value)} size="small" />
        )}
      </TableCell>
      <TableCell>
        {contactData ? (
          <Typography>{organization}</Typography>
        ) : (
          <TextField value={organization} onChange={(event) => setOrganization(event.target.value)} size="small" />
        )}
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          disabled={!canUpdate || voiceIsLoading || personIsLoading}
          onClick={() => {
            if (personData?.id !== voice.person_id) {
              updateVoicePersonId({
                voice_id: voice.id,
                person_id: personData?.id,
              })
            }
            if (personData) {
              if (personData.name !== name || personData.title !== title || personData.organization !== organization) {
                updatePerson({ id: personData.id, name, title, organization })
              }
            }
          }}
        >
          Update
        </Button>
      </TableCell>
    </>
  )
}
