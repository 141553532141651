export const ModelOptions = ["gemini-1.5-pro-002", "gemini-1.5-flash-002", "gemini-2.0-flash-exp"] as const

export type LLMModelOptions = (typeof ModelOptions)[number]

export interface ModelConfigurations {
  model: LLMModelOptions
  useFineTunedModel?: boolean
  maxTokenSizePerChunk?: number
}

export interface RunIdentifySpeakerOptions {
  meetingId: number
  dryRun?: boolean
}

export interface RunIdentifySpeakerResponse {}
