import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { Person } from "@interfaces/person"
import { Contact } from "@interfaces/contact"

const prefix = "/persons"

export const useFetchPerson = (id: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["person", id], async () => {
    const response = await (await axiosInstance).get<Person<Contact>>(`${prefix}/${id}`)
    return response.data
  })
}
