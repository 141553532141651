interface ShareResponseObjectAttributes {
  id: number
  created_at?: string
  updated_at?: string
}

export interface Job extends ShareResponseObjectAttributes {
  started_at?: string
  finished_at?: string
  success?: boolean
  video_id?: number
  proxy?: string
  error?: string
  proxy_id?: number
  in_progress?: boolean
  scraped_domains_id?: number
  execution_id?: string
  domain_id?: string
}

export interface Proxy extends ShareResponseObjectAttributes {
  name?: string
  external_ip?: string
  internal_ip?: string
  zone?: string
  provider?: string
  suspended_until?: string
  disabled?: boolean
  environment_type?: EnvironmentType
  agent_type?: ProxyType
  max_connections?: number
}

export interface ProxyDomainConfig extends ShareResponseObjectAttributes {
  proxy_id?: number
  domain_id?: number
  suspended_until?: string
  disabled?: boolean
}

export interface Domain extends ShareResponseObjectAttributes {
  domain?: string
  notes?: string
  max_concurrent_downloads?: number
  proxies_scraped_domains_configs?: ProxyDomainConfig[]
}

export interface Video extends ShareResponseObjectAttributes {
  url?: string
  transcription_job_id?: string
  checksum?: string
  bucket?: string
  success?: boolean
  attempts?: number
  scheduled_at?: string
  enqueued_at?: string
  error?: string
  meeting_id?: string
  media_type?: MediaType
  storage_type?: StorageType
  proxy_type?: ProxyType
  jobs?: Job[]
}

export enum ProxyType {
  SQUID = "squid",
  ZYTE = "zyte",
  ZYTE_SESSION = "zyte_session",
}

export enum StorageType {
  PERSISTENT = "persistent",
  TEMPORARY = "temporary",
}

export enum MediaType {
  VIDEO = "video",
  AUDIO = "audio",
}

export type EnvironmentType = {
  LOCAL: string
  QA: string
  STAGING: string
  PRODUCTION: string
  TEST: string
}

export const Environments: EnvironmentType = {
  LOCAL: "0",
  QA: "1",
  STAGING: "2",
  PRODUCTION: "3",
  TEST: "4",
}

export type EnvironmentEnum = (typeof Environments)[keyof typeof Environments]

export function getKeyByValue(object: EnvironmentType, value: EnvironmentEnum) {
  const res = Object.keys(object).find((key: EnvironmentEnum) => {
    return object[key as keyof EnvironmentType] == value
  })

  return res
}
