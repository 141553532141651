import { createContext, FC, useMemo, useState } from "react"
import { DataTable } from "../DataTable"
import { useFetchContacts } from "../../../api/contacts"
import { Skeleton, Typography } from "@mui/material"
import { formatLink } from "@components/shared/DataTable/formatters"
import { ContactFilters } from "./filters"
import { Contact } from "@interfaces/contact"

export interface ContactsFilterContextProps {
  nameFilter: string
  setNameFilter: (name: string) => void
  rolesFilter: string[]
  setRolesFilter: (roles: string[]) => void
  contacts: Contact[]
}

export const ContactsFilterContext = createContext<ContactsFilterContextProps>({
  nameFilter: "",
  setNameFilter: () => {
    /* placeholder for typescript */
  },
  rolesFilter: [],
  setRolesFilter: () => {
    /* placeholder for typescript */
  },
  contacts: [],
})

export const ContactsTable: FC<{
  organizationId: number
}> = ({ organizationId }) => {
  const { data, isLoading, isSuccess } = useFetchContacts(organizationId)
  const [name, setName] = useState<string>("")
  const [roles, setRoles] = useState<string[]>([])

  const filterContext = {
    nameFilter: name,
    setNameFilter: setName,
    rolesFilter: roles,
    setRolesFilter: setRoles,
    contacts: data || [],
  }

  const filteredData = useMemo(() => {
    let newData: Contact[] = []
    if (data) {
      newData = data.filter((contact) => contact.removed_at === null)?.slice(0, 100)
    }

    if (roles.length > 0 && newData.length > 0) {
      newData = newData.filter((contact) => roles.includes(contact.role))
    }

    if (name.length > 0 && newData.length > 0) {
      newData = newData.filter((contact) => contact.name.trim().toLowerCase().includes(name.trim().toLowerCase()))
    }

    return newData
  }, [data, roles, name])

  return (
    <>
      {isLoading && <Skeleton variant="rectangular" height={350} />}
      {data && data.length > 0 && (
        <ContactsFilterContext.Provider value={filterContext}>
          <ContactFilters />
          <DataTable
            columns={[
              { id: "name", label: "Name" },
              { id: "title", label: "Title" },
              { id: "role", label: "Role" },
              { id: "email", label: "Email", formatter: formatLink },
              { id: "phone", label: "Phone" },
            ]}
            data={filteredData}
            stickyHeader
          />
        </ContactsFilterContext.Provider>
      )}
      {isSuccess && filteredData.length === 0 && (
        <Typography padding={1} variant="h4">
          No contacts available
        </Typography>
      )}
    </>
  )
}
