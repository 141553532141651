import { AdminContact, Contact } from "@interfaces/contact"
import { Person } from "@interfaces/person"

export function decidePersonFields<T extends Contact | AdminContact = Contact>(
  person: Person<T> | undefined,
  include_unknown_fields: true,
): { name: string; title: string; organization: string }
export function decidePersonFields<T extends Contact | AdminContact = Contact>(
  person: Person<T> | undefined,
  include_unknown_fields: false,
): { name?: string; title?: string; organization?: string }
export function decidePersonFields<T extends Contact | AdminContact = Contact>(
  person?: Person<T>,
  include_unknown_fields: boolean = true,
) {
  const personContact = person?.contact
  let name = include_unknown_fields ? "Unknown" : undefined
  let title = include_unknown_fields ? "Unknown" : undefined
  let organization = include_unknown_fields ? "Unknown" : undefined
  if (personContact) {
    if ("first_name" in personContact) {
      name = `${personContact.first_name} ${personContact.last_name}`
    } else if ("name" in personContact) {
      name = personContact.name
    }
    title = personContact.title ? personContact.title : include_unknown_fields ? "Unknown" : undefined
    // If org.name is available, use it. Otherwise, use person.title if available. Otherwise, use "Unknown" if include_unknown_fields is true. Otherwise, use undefined.
    organization = personContact.organization?.name
      ? personContact.organization.name
      : person.title
        ? person.title
        : include_unknown_fields
          ? "Unknown"
          : undefined
  } else if (person) {
    name = person.name.toLowerCase() === "unknown" && !include_unknown_fields ? undefined : person.name
    title = person.title.toLowerCase() === "unknown" ? undefined : person.title
    organization = person.organization.toLowerCase() === "unknown" ? undefined : person.organization
  }
  return { name, title, organization }
}
