import { LLMModelOptions, ModelConfigurations, ModelOptions } from "@interfaces/speaker"
import { Box, Checkbox, FormControlLabel, FormGroup, Input, MenuItem, Select, Stack } from "@mui/material"
import { Dispatch, FC, SetStateAction } from "react"

export interface CustomModelConfigProps {
  modelOptions: ModelConfigurations
  setModelOptions: Dispatch<SetStateAction<ModelConfigurations>>
}

export const CustomModelConfig: FC<CustomModelConfigProps> = ({ modelOptions, setModelOptions }) => {
  const handleOptionsChange = <K extends keyof ModelConfigurations>(key: K, value: ModelConfigurations[K]) => {
    setModelOptions((prevVal) => {
      return { ...prevVal, [key]: value }
    })
  }

  return (
    <Box>
      <Stack direction={"column"} spacing={1} marginBottom={1}>
        <Stack direction="row" spacing={1} marginBottom={1}>
          <Select
            value={modelOptions?.model}
            onChange={(e) => handleOptionsChange("model", e.target.value as LLMModelOptions)}
            displayEmpty
          >
            {ModelOptions.map((model) => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={modelOptions?.model !== "gemini-1.5-flash-002"}
                  onChange={(e) => handleOptionsChange("useFineTunedModel", e.target.checked)}
                />
              }
              label="Use Fine-tuned Model"
            />
          </FormGroup>
        </Stack>
        <Stack direction="row" spacing={1} marginBottom={1}>
          <Input
            type="number"
            defaultValue={15000}
            value={modelOptions?.maxTokenSizePerChunk}
            onChange={(e) => handleOptionsChange("maxTokenSizePerChunk", parseInt(e.target.value))}
          />
        </Stack>
      </Stack>
    </Box>
  )
}
