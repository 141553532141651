import { Autocomplete, Stack, TextField, useMediaQuery } from "@mui/material"
import { FC, useContext } from "react"
import { theme } from "@src/theme"
import { ContactsFilterContext } from "."

export const ContactFilters: FC = () => {
  const { contacts, nameFilter, setNameFilter, rolesFilter, setRolesFilter } = useContext(ContactsFilterContext)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))

  if (contacts && contacts.length > 0) {
    const uniqueRoles = Array.from(new Set(contacts.map((contact) => contact.role))).sort()

    return (
      <Stack direction={isSmallScreen ? "column" : "row"} spacing={2}>
        <TextField
          label="Name"
          size="small"
          value={nameFilter}
          onChange={(event) => setNameFilter(event.target.value)}
          sx={{ minWidth: 200 }}
        />
        <Autocomplete
          multiple
          options={uniqueRoles}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} placeholder="Role" />}
          onChange={(_, roles) => setRolesFilter(roles)}
          value={rolesFilter}
          size="small"
          fullWidth
        />
      </Stack>
    )
  } else {
    return null
  }
}
