import { useContext, FC, useState } from "react"
import { Typography, Button, Stack, TextField, Select, MenuItem, Tooltip } from "@mui/material"
import { unpackSetters } from "../unpackSetters"
import { CampaignWizardContext } from "./Context"
import { TermChipBase } from "../TermChipBase"
import { TimeDuration } from "../TimeDuration"

const dayMilliseconds = 1000 * 60 * 60 * 24
const oneWeekMilliseconds = dayMilliseconds * 7

export const SearchTerms: FC = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [mustTerm, setMustTerm] = useState("")
  const { value, setValue } = useContext(CampaignWizardContext)
  const { searchTerms, advancedSearch, filterParams, proximity, exclude } = value
  const { setSearchTerms, setAdvancedSearch, setProximity, setExclude } = unpackSetters(value, setValue)

  const addTerm = (term: string, isMustTerm?: boolean) => {
    term = term.replace(",", " ").trim()
    if (!term.replace(/\s/g, "").length) {
      return
    }
    const addTermToSet = (prevSet: Set<string>) => {
      const newSet = new Set(prevSet)
      newSet.add(term.trim())
      return newSet
    }

    if (isMustTerm) {
      setAdvancedSearch(addTermToSet)
    } else {
      setSearchTerms(addTermToSet)
    }
  }

  const addTermFromTextField = (isMustTerm?: boolean) => {
    if (isMustTerm) {
      addTerm(mustTerm, isMustTerm)
      setMustTerm("")
    } else {
      addTerm(searchTerm, isMustTerm)
      setSearchTerm("")
    }
  }

  const removeTerm = (term: string, isMustTerm?: boolean) => () => {
    if (isMustTerm) {
      setAdvancedSearch((prevSet) => {
        const newSet = new Set(prevSet)
        newSet.delete(term)
        return newSet
      })
    } else {
      setSearchTerms((prevSet) => {
        const newSet = new Set(prevSet)
        newSet.delete(term)
        return newSet
      })
    }
  }

  const searchDateRange: [Date, Date] = [
    new Date(Math.floor((Date.now() - oneWeekMilliseconds) / dayMilliseconds) * dayMilliseconds),
    new Date(Math.ceil(Date.now() / dayMilliseconds) * dayMilliseconds),
  ]

  return (
    <>
      <Stack spacing={1}>
        <Typography variant="h1">Enter Your Search Terms</Typography>
        <Typography variant="body1">
          Add as many terms as you&apos;d like here. Note: too many terms can create noisy search results.
        </Typography>
      </Stack>
      <Stack marginTop={2} marginBottom={4} spacing={1}>
        <Stack direction="row" spacing={3} alignItems="center">
          <TextField
            label="Search Terms"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            fullWidth
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                addTermFromTextField()
              }
            }}
          />
          <Button variant="contained" sx={{ whiteSpace: "nowrap" }} onClick={() => addTermFromTextField()}>
            Add Term
          </Button>
        </Stack>
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {Array.from(searchTerms).map((searchTerm) => (
            <TermChipBase
              key={searchTerm}
              term={searchTerm}
              onDelete={removeTerm(searchTerm)}
              filterParams={filterParams}
              searchDateRange={searchDateRange}
            />
          ))}
        </Stack>
      </Stack>
      <Stack marginTop={6} marginBottom={4} spacing={1} direction={{ xs: "column" }}>
        <Typography variant="body1">
          Add any term that {exclude ? "MUST NOT " : "MUST "}be in a meeting. Note: this can severely limit search
          results.
        </Typography>
        <Stack direction="row" spacing={3} alignItems="center">
          <TextField
            label="Anchor Term (s)"
            value={mustTerm}
            onChange={(event) => setMustTerm(event.target.value)}
            fullWidth
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                addTermFromTextField(true)
              }
            }}
          />
          <Button variant="contained" sx={{ whiteSpace: "nowrap" }} onClick={() => addTermFromTextField(true)}>
            Add Term
          </Button>
        </Stack>
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {Array.from(advancedSearch).map((searchTerm) => (
            <TermChipBase
              key={searchTerm}
              term={searchTerm}
              onDelete={removeTerm(searchTerm, true)}
              filterParams={filterParams}
              searchDateRange={searchDateRange}
            />
          ))}
        </Stack>
        <Typography variant="body1">
          Time Range: how close together do you want the terms to be? Leave blank for no proximity.
        </Typography>
        <Stack>
          <TimeDuration proximity={proximity} setProximity={setProximity} />
        </Stack>
        <Typography>Include or Exclude the Anchor Term (s)?</Typography>
        <Select
          value={exclude ? "exclude" : "include"}
          onChange={(event) => setExclude(event.target.value === "exclude")}
        >
          <MenuItem value="include">Must Include</MenuItem>
          <MenuItem value="exclude">Must Exclude</MenuItem>
        </Select>
      </Stack>
    </>
  )
}
